import React from "react";
import { UserContext } from "../UserContext";
import FormContainer from "./FormContainer";
import useQuery from "../Hooks/useQuery";
import Button from "./Forms/Button";
import Input from "./Forms/Input";
import styles from "./LegacyLogin.module.css";

const Confirm2FA = () => {
  // eslint-disable-next-line no-unused-vars
  let { message, validate2FASignin, start2FAvalidation, confirmationCodeLength } = React.useContext(UserContext);
  let query = useQuery();

  const [brand] = React.useState(query.get("brand"));

  React.useEffect(() => {
    start2FAvalidation();
  }, []);

  return (
    <FormContainer className={styles.container} footer={true}>
      <div className={`${styles.controls} ${brand ? styles[brand] : ""}`}>
        <h2>{brand ? brand : ""} SIGN IN</h2>

        <p className={styles.loginMessage}>{message}</p>

        <form className={styles.form} onSubmit={(e) => {
          e.preventDefault();
          validate2FASignin(document.getElementById("code").value,query.get("email"));
        }}>
          <Input
            type="text"
            name="code"
            maxLength={confirmationCodeLength}
            id="code"
            autoFocus
            style={{ textAlign:"center" }}
          />

          <Button>Confirm</Button>
        </form>
      </div>
    </FormContainer>
  );
};

export default Confirm2FA;