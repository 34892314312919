import React from "react";

const useFetch = () => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(null);

  const request = React.useCallback(async (url, options, forceJson = false) => {
    let response;
    let json;

    try {
      setError(null);
      setLoading(true);

      response = await fetch(url, options);

      json = await response.json();

      if (!response.ok) throw new Error(json.error);
    } catch (error) {
      if (!forceJson) json = null;
      setError(error.message);
    } finally {
      setData(json);
      setLoading(false);
    }

    return { response, json };
  }, []);

  return {
    data,
    loading,
    error,
    request,
  };
};

export default useFetch;
