/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import useForm from "../Hooks/useForm";
import { UserContext } from "../UserContext";
import Button from "./Forms/Button";
import Input from "./Forms/Input";

import styles from "./LegacyLogin.module.css";

const Register = ({ userData, loginSource, setEmail }) => {
  let { data, loading, verifyEmail, registerUser } =
    React.useContext(UserContext);

  // let email = useForm();
  let organization = useForm();

  let first_name = useForm();
  let last_name = useForm();
  let phone = useForm();
  let email = useForm("companyEmail",async (value,setError) => {
    if(value.includes("@")) {
      verifyEmail(value,(res) => {
        if(res.status === 400) {
          setAllowedToOrganization(false);
          
          if(res.message === "Email is already taken.") {
            setError(<>
              {res.message} Do you want to <a href="https://auth.citydata.ai/signin">sign</a> in instead?
            </>);
          } else if (res.message === "Public email domain is not allowed.") {
            setOrganizationType("singleUser");
          }
        } else {
          setAllowedToOrganization(true);
        }
      });
    }    
  });
  let password = useForm();

  const statusMessage = "Sign up";

  let [AllowedToPersonalUse, setAllowedToPersonalUse] = React.useState(true);
  let [AllowedToOrganization, setAllowedToOrganization] = React.useState(false);
  let [organizationName, setOrganizationName] = React.useState("");
  let [organizationFound, setOrganizationFound] = React.useState(false);
  let [initialData, setInitialData] = React.useState(null);

  function findOrganization(userEmail){
    return new Promise((resolve,reject) => {
      if(!AllowedToOrganization) {
        setOrganizationFound(false);
        setOrganizationName("");
        resolve();  
      } else {
        fetch(`https://cityapi.lotadata.com/auth/organizations/matchDomain?domain=${userEmail.split("@")[1]}`)
          .then(res => res.json())
          .then(organizationData => {
            
            if(organizationData.length) {
              setOrganizationFound(organizationData);
              setOrganizationName(organizationData[0].name);
              setOrganizationType("hasOrganization");
              setAllowedToPersonalUse(false);
              setAllowedToOrganization(true);
            } else {
              setOrganizationName("");
              setOrganizationType(null);
              setAllowedToPersonalUse(true);
              setAllowedToOrganization(true);
            }
          })
          .then(resolve);
      }
    });
  }
  async function handleSubmit(event) {
    event.preventDefault();
    // if (await email.validate()) {
      // if (organization.validate()) {
        setEmail(email.value);

        if(!organizationFound || !organizationFound.length) {
          fetch("https://cityapi.lotadata.com/auth/organizations", {
            method: "POST",
            headers: {
              "Content-Type":"application/json"              
            },
            body: JSON.stringify({
              "admin_email": email.value,
              "admin_user": `${first_name.value} ${last_name.value}`,
              "admin_uid": null,
              "domain": email.value.split("@")[1],
              "name": organizationName,
              "api_key": "",
              "bucket": "",
              "elastic_id": ""
            }),
            redirect: "follow"
          }).then(orgRes => {
            registerUser(
              first_name.value,
              last_name.value,
              phone.value,
              email.value,
              password.value,
              loginSource,
              orgRes.id
            );
          });
        } else {
          if(organizationType === "hasOrganization") {
            registerUser(
              first_name.value,
              last_name.value,
              phone.value,
              email.value,
              password.value,
              loginSource,
              organizationFound[0].id
            );
          } else {
            registerUser(
              first_name.value,
              last_name.value,
              phone.value,
              email.value,
              password.value,
              loginSource
            );
          }
        }
      // }
    // }
  }

  React.useEffect(() => {
    setInitialData(userData);
  }, []);

  React.useEffect(() => {
    if (data && "org" in data && data.org) {
      organization.setValue(data.org);
      organization.validate();
      setOrganizationFound(true);
    } else {
      organization.setValue("");
      setOrganizationFound(false);
    }
  }, [data]);

  const [step,setStep] = React.useState(1);
  const [organizationType,setOrganizationType] = React.useState(null);

  return (
    <form className={styles.form} onSubmit={handleSubmit} style={{
      minHeight: "488.56px",
      display: "flex",
      justifyContent: "space-between"      
    }}>
      {step == 1 && <>
        <Input
          label="First Name"
          type="text"
          name="first_name"
          placeholder=""
          autoComplete="off"
          {...first_name}
          autoFocus
        />
        <Input
          label="Last Name"
          placeholder=""
          type="text"
          name="last_name"
          {...last_name}
        />
        <Input
          label="Phone"
          type="text"
          name="phone"
          placeholder=""
          autoComplete="off"
          {...phone}
        />
        <Input
          label="Work Email"
          placeholder=""
          type="email"
          name="email"
          {...email}
        />
        <Input
          label="Password"
          placeholder=""
          type="text"
          name="password"
          {...password}
        />

        <Button type="button"
          onClick={() => {
            findOrganization(email.value).then(() => setStep(step+1));
          }}
          disabled={
            !first_name.value || !last_name.value || !phone.value || !email.value || !password.value
          }
        >Next</Button>
      </>}

      {step == 2 && <>
        <ul style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%"
        }}>
          <li>
            <label htmlFor="singleUser">
              <input type="radio"
                      name="organization_type"
                      value="singleUser"
                      id="singleUser"
                      disabled={organizationFound}
                      onChange={(e) => setOrganizationType(e.target.value)}/>
              <span>Single User</span>
            </label>
          </li>
          <li>
            <label htmlFor="hasOrganization">
              <input type="radio"
                      name="organization_type"
                      value="hasOrganization"
                      id="hasOrganization"
                      checked={organizationFound}
                      onChange={(e) => setOrganizationType(e.target.value)}/>
              <span>Has Organization</span>
            </label>
          </li>
        </ul>

        {organizationType && organizationType === "hasOrganization" && <>
            <Input
              label="Organization Name"
              type="text"
              name="organization_name"
              autoComplete="off"
              value={organizationName}
              disabled={organizationFound}
              onChange={(e) => setOrganizationName(e.target.value)}
              autoFocus
            />

            {!organizationFound && <>
              <Input
                label="Organization Domain"
                type="text"
                name="organization_domain"
                autoComplete="off"
                value={email.value.split("@")[1]}
                disabled
              />
            </>}
        </>}

        <div style={{
          display:"flex",
          width: "80%"
        }}>
          <Button type="button" onClick={() => setStep(step-1)}
          style={{
            marginRight: "5px"
          }}>Previous</Button>
          <Button type="button" onClick={() => setStep(step+1)}
          style={{
            marginLeft: "5px"
          }}>Next</Button>
        </div>
      </>}

      {step == 3 && <>
        <p> Confirm your account creation </p>
        {loading ? (
          <Button disabled="disabled">{statusMessage}</Button>
        ) : (
          <Button>{statusMessage}</Button>
        )}
      </>}
    </form>
  );
};

export default Register;
