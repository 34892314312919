/* eslint-disable no-undef */
export const CITYAPI_URL = `https://${
  process.env.REACT_APP_ENV === "beta" ? "beta." : ""
}cityapi.lotadata.com`;

export const CITYDASH_URL = `https://${
  process.env.REACT_APP_ENV === "beta" ? "beta." : ""
}citydash.ai`;

export const API2_URL = `https://${
  process.env.REACT_APP_ENV === "beta" ? "beta." : "api2."
}lotadata.com/v2`;

export function USER_GET(apiKey) {
  return {
    url: `https://cityapi.lotadata.com/auth/checkToken?token=${apiKey}`
  };
}

export function VALIDATE_GET(code) {
  return {
    url: `${CITYAPI_URL}/session/activate?code=${code}`,
  };
}

export function USER_LOGIN_POST(body) {
  return {
    url: "https://cityapi.lotadata.com/auth/signIn",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  };
}

export function REGISTER_USER_POST(body) {
  return {
    url: "https://cityapi.lotadata.com/auth/signUp",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  };
}

export function LINKEDIN_AUTH_POST(body) {
  return {
    url: `${CITYAPI_URL}/auth/linkedin/login/citydash`,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  };
}

export function GOOGLE_AUTH_POST(body) {
  return {
    url: `${CITYAPI_URL}/auth/google/login/citydash`,
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    },
  };
}

export function VERIFY_EMAIL(email) {
  return {
    url: `${API2_URL}/session/verifyEmail/${email}`,
    options: {
      method: "GET",
    },
  };
}
