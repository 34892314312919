/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import FormContainer from "./FormContainer";
import homeStyles from "./Home.module.css";
import styles from "./ThirdPartyLogin.module.css";

import { UserContext } from "../UserContext";
import Register from "./Register";
import { useLocation } from "react-router";

import { ReactComponent as Check } from "../Assets/svg/check.svg";

const SignIn = () => {
  // eslint-disable-next-line no-unused-vars
  const { pathname } = useLocation();
  const [email, setEmail] = React.useState(null);

  let loginSource = null;
  if(pathname.match(/linkedin/i)) {
    loginSource = "linkedin";
  } else if(pathname.match(/google/i)) {
    loginSource = "google";
  } else {
    loginSource = "firebase";
  }

  let {
    verification,
    data
  } = React.useContext(UserContext);

  let formChild = <Register
    userData={data}
    loginSource={loginSource}
    setEmail={setEmail}
  />;

  if(verification === true) {
    formChild = <div className={styles.verification}>
      <Check />
      <p>
        We have sent a confirmation email to <b>{email}</b>.
        <br />
        Please validate by clicking the link that we
        <br />
        just sent to you so that you can proceed to sign in.
      </p>
      <p>
        Already validated? <br/>
        <Link
          className={styles.legacy}
          to="/signin"
          aria-label="Sign In"
        >
          Sign In
        </Link>        
      </p>
    </div>;
  }

  return (
    <FormContainer className={styles.container} footer={false}>
      <div className={homeStyles.controls}>
        <h2>CREATE ACCOUNT</h2>

        {formChild}
      </div>      
    </FormContainer>
  );
};

export default SignIn;
