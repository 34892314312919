import React from "react";
import { UserContext } from "../UserContext";
import FormContainer from "./FormContainer";
import useQuery from "../Hooks/useQuery";
import styles from "./LegacyLogin.module.css";
import { Link } from "react-router-dom";

const confirmAccount = () => {
    let query = useQuery();
    const [validated,setValidated] = React.useState(false); 
    const [message,setMessage] = React.useState("Validating your account"); 
    const [brand] = React.useState(query.get("brand")); 
    const [oobCode] = React.useState(query.get("oobCode")); 

    const { verifyAccount } = React.useContext(UserContext);
    React.useEffect(async () => {
        await verifyAccount(oobCode);
        setMessage("Your account is validated");
        setValidated(true);
    },[]);

    return (<FormContainer className="animeLeft">
        <div className={`${styles.controls} ${brand ? styles[brand] : ""}`}>
            <h2>{message}</h2>

            <p>{validated && <Link
                className={styles.legacy}
                to="/signin"
                aria-label="Sign In"
                >
                Sign In
            </Link>}</p>
        </div>
    </FormContainer>);    
};
export default confirmAccount;