/* eslint-disable no-undef */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import FormContainer from "./FormContainer";
import LinkedInButton from "./Forms/LinkedInButton";
import GoogleButton from "./Forms/GoogleButton";
import styles from "./Home.module.css";
import { LinkedIn } from "react-linkedin-login-oauth2";
import Error from "../Helpers/Error";
import { GoogleLogin } from "react-google-login";

const Home = () => {
  const [linkedInCode, setLinkedInCode] = React.useState(null);
  const [googleCode, setGoogleCode] = React.useState(null);
  const [error, setError] = React.useState(null);

  const history = useHistory();

  function linkedInSuccess(data) {
    setLinkedInCode(data.code);
    setError(null);
  }

  function linkedInFailure(error) {
    setLinkedInCode(null);
    setError(error.errorMessage);
  }

  const googleSuccess = (response) => {
    setGoogleCode(response.tokenId);
    setError(null);
  };

  function googleFailure(error) {
    setGoogleCode(null);
    setError(error.details);
  }

  React.useEffect(() => {
    if (window.localStorage.getItem("token")) history.push("/continue");
  }, []);

  React.useEffect(() => {
    if (linkedInCode) {
      window.localStorage.setItem("linkedinAuthToken", linkedInCode);
      history.push("/linkedin/callback");
    }
  }, [linkedInCode]);

  React.useEffect(() => {
    if (googleCode) {
      window.localStorage.setItem("googleAuthToken", googleCode);
      history.push("/google/callback");
    }
  }, [googleCode]);

  const redirectTo = new URLSearchParams(window.location.search).get("redirectTo");
  if(redirectTo) {
    window.localStorage.setItem("redirectTo",redirectTo);
  }

  const showSocialLoginButtons = false;

  return (
    <FormContainer className="animeLeft">
      <div className={styles.controls}>
        <h2>SIGN IN</h2>
        <div className={styles.buttons}>
          { showSocialLoginButtons ? <>
            <LinkedIn
              clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
              redirectUri={`${window.location.origin}/linkedin`}
              scope="r_liteprofile r_emailaddress"
              onFailure={linkedInFailure}
              onSuccess={linkedInSuccess}
              supportIE
              redirectPath="/linkedin"
              renderElement={({ onClick, disabled }) => (
                <LinkedInButton onClick={onClick} disabled={disabled}>
                  Sign In with LinkedIn
                </LinkedInButton>
              )}
            />
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText="Login"
              onSuccess={googleSuccess}
              onFailure={googleFailure}
              cookiePolicy={"single_host_origin"}
              render={({ onClick, disabled }) => (
                <GoogleButton onClick={onClick} disabled={disabled}>
                  Sign In with Google
                </GoogleButton>
              )}
            />
          </> : <></>}
          <Link
            className={styles.legacy}
            to="/signin"
            aria-label="Sign In"
          >
            Sign In
          </Link>
          <Link
            className={styles.legacy}
            to="/signup"
            aria-label="Create Account"
          >
            Create Account
          </Link>
          <Error error={error} className={styles.error} />
        </div>
      </div>
    </FormContainer>
  );
};

export default Home;
