/* eslint-disable no-unused-vars */
import React from "react";
import { UserContext } from "../UserContext";
import FormContainer from "./FormContainer";
import styles from "./ThirdPartyLogin.module.css";
import useQuery from "../Hooks/useQuery";

const Validate = () => {
  let { message, validateAccount } = React.useContext(UserContext);
  let query = useQuery();

  React.useEffect(() => {
    async function validate() {
      await validateAccount(query.get("code"));
    }
    validate();
  }, []);

  return (
    <FormContainer className={styles.container} footer={false}>
      <div>
        <div className={styles.logosContainer}>
          <img src="https://cityworks.ai/auth/static/media/citydata.d57d1ebf.png" className={styles.logo} />
        </div>
        <div className={styles.loadBar}></div>
        <p className={styles.loginMessage}>{message}</p>
      </div>
    </FormContainer>
  );
};

export default Validate;
