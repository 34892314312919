import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";
// import Footer from "./Components/Footer";
// import ProtectedRoute from "./Components/Helpers/ProtectedRoute";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import Home from "./Components/Home";
import LegacyLogin from "./Components/LegacyLogin";
import ThirdPartyLogin from "./Components/ThirdPartyLogin";
// import Login from "./Components/Login/Login";
// import User from "./Components/User/User";
import { UserStorage } from "./UserContext";
import Continue from "./Components/Continue";
import Validate from "./Components/Validate";

import SignUp from "./Components/SignUp";
import Recover from "./Components/Recover";
import Confirm2FA from "./Components/Confirm2FA";
import confirmAccount from "./Components/confirmAccount";
import resetPassword from "./Components/resetPassword";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <UserStorage>
          <Switch>
            <Route exact path="/resetPassword" component={resetPassword} />
            <Route exact path="/confirmAccount" component={confirmAccount} />
            <Route exact path="/confirm" component={Confirm2FA} />
            <Route exact path="/linkedin" component={LinkedInPopUp} />
            <Route
              exact
              path="/linkedin/callback"
              component={ThirdPartyLogin}
            />
            <Route exact path="/google/callback" component={ThirdPartyLogin} />
            <Route exact path="/continue" component={Continue} />
            <Route exact path="/register/continue" component={Validate} />
            <Route path="/legacy" component={LegacyLogin} />
            <Route path="/internal/login" component={LegacyLogin} />

            <Route path="/signin" component={LegacyLogin} />
            <Route path="/signup" component={SignUp} />

            <Route path="/recover" component={Recover} />

            <Route path="/" component={Home} />
            {/* <ProtectedRoute path="conta/*" element={<User />} /> */}
          </Switch>
          {/* <Footer /> */}
        </UserStorage>
      </BrowserRouter>
    </div>
  );
};

export default App;
