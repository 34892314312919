import React from "react";
import { UserContext } from "../UserContext";
import FormContainer from "./FormContainer";
import styles from "./ThirdPartyLogin.module.css";
import useQuery from "../Hooks/useQuery";

const Continue = () => {
  // eslint-disable-next-line no-unused-vars
  let { message, tokenIsValid } = React.useContext(UserContext);
  let query = useQuery();

  const [brand] = React.useState(query.get("brand"));

  React.useEffect(() => {
    async function continueSession() {
      await tokenIsValid({ brand });
    }

    continueSession();
  }, []);

  return (
    <FormContainer className={styles.container} footer={false}>
      <div>
        <div className={styles.logosContainer}>
          <img src="https://cityworks.ai/auth/static/media/citydata.d57d1ebf.png" className={styles.logo} />
        </div>
        <div className={styles.loadBar}></div>
        <p className={styles.loginMessage}>{message}</p>
      </div>
    </FormContainer>
  );
};

export default Continue;
