/* eslint-disable react/prop-types */
import React from "react";
import FormContainer from "./FormContainer";
import styles from "./ThirdPartyLogin.module.css";

import linkedinLogo from "../Assets/logos/linkedin-logo.png";
import googleLogo from "../Assets/logos/google-logo.png";
import { UserContext } from "../UserContext";
import Register from "./Register";
import { useLocation } from "react-router";

import { ReactComponent as Check } from "../Assets/svg/check.svg";

const ThirdPartyLogin = () => {
  // eslint-disable-next-line no-unused-vars
  const { pathname } = useLocation();
  const [email, setEmail] = React.useState(null);

  const loginSource = pathname.match(/linkedin/i) ? "linkedin" : "google";

  let {
    linkedinLogin,
    register,
    message,
    authentication,
    verification,
    data,
    googleLogin,
  } = React.useContext(UserContext);

  React.useEffect(() => {
    async function auth() {
      if (pathname.match(/linkedin/i)) {
        await linkedinLogin(
          `${window.location.origin}/linkedin`,
          `${window.location.origin}/linkedin`
        );
      } else if (pathname.match(/google/i)) {
        await googleLogin(
          `${window.location.origin}/google`,
          `${window.location.origin}/google`
        );
      }

      return () => {
        // removed code
      };
    }

    auth();
  }, []);

  return (
    <FormContainer className={styles.container} footer={false}>
      {register === true && data ? (
        <Register
          userData={data}
          loginSource={loginSource}
          setEmail={setEmail}
        />
      ) : verification === true ? (
        <div className={styles.verification}>
          <Check />
          <p>
            {authentication ? (
              <>
                We have sent an email to <b>{email}</b>.
                <br />
                Please validate by clicking the link that we
                <br />
                just sent to you so that you can proceed to sign in.
              </>
            ) : (
              <>
                We have sent a confirmation email to <b>{email}</b>.<br />
                Please validate by clicking the link that we
                <br />
                just sent to you so that you can proceed to sign in.
              </>
            )}
          </p>
        </div>
      ) : (
        <div>
          <img
            src={pathname.match(/linkedin/i) ? linkedinLogo : googleLogo}
            className={styles.logo}
          />
          <div className={styles.loadBar}></div>
          <p className={styles.loginMessage}>{message}</p>
        </div>
      )}
    </FormContainer>
  );
};

export default ThirdPartyLogin;
