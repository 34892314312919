import React from "react";
import { useHistory } from "react-router";
import * as api from "./api";
// import { useNavigate } from "react-router-dom";
import useFetch from "./Hooks/useFetch";

export const UserContext = React.createContext();

// eslint-disable-next-line react/prop-types
export const UserStorage = ({ children }) => {
  const [data, setData] = React.useState(null);
  const [login, setLogin] = React.useState(null);
  const [authentication, setAuthentication] = React.useState(null);
  const [verification, setVerification] = React.useState(null);
  const [register, setRegister] = React.useState(null);
  const [message, setMessage] = React.useState(null);

  const confirmationCodeLength = 6;
  const { loading, error, request } = useFetch();
  const history = useHistory();
  // const navigate = useNavigate();

  // async function getUser(token) {
  //   const { url, options } = api.USER_GET(token);

  //   const { json } = await request(url, options);

  //   setData(json);
  //   setLogin(true);
  // }

  async function updatePassword(oobCode,password,confirm_password) {
    return new Promise((resolve,reject) => {
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify({
          "email": window.localStorage.getItem("email"),
          "password": password,
          "confirm_password": confirm_password
        }),
        redirect: "follow"
      };
      
      fetch(`https://cityapi.lotadata.com/auth/updatePassword?code=${oobCode}`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if(result.success) {
            resolve({
              success: true
            });
          } else {
            reject({
              success: false,
              message: result.message
            });
          }
        })
        .catch((error) => reject(error)); 
    });
  }

  async function verifyAccount(oobCode) {
    var requestOptions = {
      method: "get",
      redirect: "follow"
    };

    fetch(`https://cityapi.lotadata.com/auth/verifyAccount?code=${oobCode}`, requestOptions)
      .then(() => {
        setMessage("Check your email!");
        setLogin(true);
      })
      .catch(error => console.log("error", error)); 
  }

  async function recoverPassword(userEmail) {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      
      fetch(`https://cityapi.lotadata.com/auth/resetPassword?email=${userEmail}`, requestOptions)
        .then(() => {
          setMessage("Check your email!");
          setLogin(true);          
        })
        .catch(error => console.log("error", error));
  }

  function forwardUser(token) {
    const redirectTo = new URLSearchParams(window.location.search).get("redirectTo") || window.localStorage.getItem("redirectTo");
    let endpoint = undefined;
    if(redirectTo) {
      window.localStorage.removeItem("redirectTo");
      endpoint = `${redirectTo}?Session_Key=${token}&source=external`;
    } else {
      endpoint = `https://citydata.ai/search/?Session_Key=${token}&source=external`;
    }

    location.href = endpoint;
  }

  async function tokenIsValid(params) {
    setMessage("Continuing...");

    if (!window.localStorage.token) {
      window.localStorage.removeItem("source");
      return false;
    }

    let token = window.localStorage.getItem("token");

    const { url, options } = api.USER_GET(token);

    const { response } = await request(url, options);

    if (!response.ok) {
      setMessage("Your session has expired...");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("source"); 

      setTimeout(() => {
        setMessage("You will need to sign in again.");
        setTimeout(() => {
          params.brand
            ? history.push({
                pathname: "/internal/login",
                search: `${params.brand ? `?brand=${params.brand}` : ""}`,
              })
            : history.push("/");
        }, 2000);
      }, 2000);

      return;
    } else {
      setTimeout(() => {
        setMessage("You are signed in!");
        setLogin(true);

        setTimeout(() => {
          setMessage("Redirecting...");
          forwardUser(token);
        }, 1000);
      }, 2000);
    }

    return false;
  }

  async function validateAccount(code) {
    setMessage("Checking validation code...");

    const { url, options } = api.VALIDATE_GET(code);

    const { response, json } = await request(url, options);

    if (!response.ok) {
      setMessage("Your validation token has been expired");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("source");

      setTimeout(() => {
        setMessage("You will need to sign in again.");
        setTimeout(() => {
          history.push("/");
        }, 2000);
      }, 2000);

      return;
    } else {
      setTimeout(() => {
        setMessage("Your account was successfully activated.");

        setTimeout(() => {
          setMessage("You are signed in!");
          setLogin(true);

          setTimeout(() => {
            let token = json.token;

            window.localStorage.setItem("token", token);

            setMessage("Redirecting...");
            forwardUser(token);
          }, 1000);
        }, 2000);
      }, 2000);
    }
  }

  async function start2FAvalidation() {
    setMessage("Provide 6 digit code sent to your email");
  }

  async function validate2FASignin(validationCode,email) {
    fetch(`https://cityapi.lotadata.com/auth/verify2FA?code=${validationCode}&email=${email}`, { method: "GET", redirect: "follow" })
      .then((response) => response.json())
      .then((json) => {
        setMessage("You are signed in!");
        setLogin(true);

        setTimeout(() => {
          let token = json._tokenResponse.main; // elastic apikey, works for citydash, does not work for cityworks

          window.localStorage.setItem("token", token);
          setMessage("Redirecting...");
          forwardUser(token);
        }, 1000);
      })
      .catch((error) => console.error(error));
  }

  async function userLogin(email, password, legacy = false) {
    setRegister(false);
    const { url, options } = api.USER_LOGIN_POST({
      email,
      password,
    });

    try {
      setMessage("We are activating your account...");

      const { response, json } = await request(url, options);

      if (!response.ok) {
        setMessage("User or Password is invalid");

        if (!legacy) {
          setTimeout(() => {
            setMessage("Cancelling Login...");
            setTimeout(() => {
              history.push("/");
            }, 1000);
          }, 1000);
        }

        return;
      }

      if(json.waitFor2FA) {
        window.location.href = `${window.location.protocol}//${window.location.host}/confirm?email=${email}`;
      } else {
        setTimeout(() => {
          setMessage("You are signed in!");
          setLogin(true);
  
          setTimeout(() => {
            let token = json._tokenResponse.main; // elastic apikey, works for citydash, does not work for cityworks
  
            window.localStorage.setItem("token", token);
            setMessage("Redirecting...");
            forwardUser(token);
          }, 1000);
        }, 2000);
      }
    } catch (error) {
      setLogin(false);
      window.localStorage.removeItem("source");
    }
  }

  async function linkedinLogin(source, redirect) {
    let code = window.localStorage.getItem("linkedinAuthToken");
    setVerification(false);

    if (!code) {
      history.push("/");
      return;
    }

    window.localStorage.setItem("source", "linkedin");

    setMessage("We are signing in with your LinkedIn Account...");
    const { url, options } = api.LINKEDIN_AUTH_POST({
      authorization: code,
      sourceUrl: source,
      redirect,
    });

    let { response, json } = await request(url, options);

    if (!response.ok) {
      setLogin(false);
      setRegister(false);
      window.localStorage.removeItem("source");

      setMessage("Cancelling Login...");
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } else if (json.email) {
      setMessage("Completing your signup...");

      setTimeout(() => {
        setLogin(true);
        setRegister(true);
        setData(json);
      }, 2000);
    } else {
      setLogin(true);
      setRegister(false);
      setData(json);

      window.localStorage.setItem("token", json.token);

      setMessage("You are signed in!");
      setTimeout(() => {
        setMessage("Redirecting...");
        forwardUser(json.token);
      }, 2000);
    }

    window.localStorage.removeItem("linkedinAuthToken");
  }

  async function googleLogin(source, redirect) {
    let code = window.localStorage.getItem("googleAuthToken");
    setVerification(false);

    if (!code) {
      history.push("/");
      return;
    }

    window.localStorage.setItem("source", "google");

    setMessage("We are signing in with your Google Account...");
    const { url, options } = api.GOOGLE_AUTH_POST({
      authorization: code,
      sourceUrl: source,
      redirect,
    });

    let { response, json } = await request(url, options);

    if (!response.ok) {
      setLogin(false);
      setRegister(false);
      window.localStorage.removeItem("source");

      setMessage("Cancelling Login...");
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } else if (json.email) {
      setMessage("Completing your signup...");

      setTimeout(() => {
        setLogin(true);
        setRegister(true);
        setData(json);
      }, 2000);
    } else {
      setLogin(true);
      setRegister(false);
      setData(json);

      window.localStorage.setItem("token", json.token);

      setMessage("You are signed in!");
      setTimeout(() => {
        forwardUser(json.token);
      }, 2000);
    }

    window.localStorage.removeItem("googleAuthToken");
  }

  async function registerUser(first_name,last_name,phone,email,password,loginSource,orgId = null) {
    setRegister(false);
    setVerification(false);

    const { url, options } = api.REGISTER_USER_POST({
      first_name,last_name,phone,email,password,loginSource,orgId
    });

    return new Promise((resolve,reject) => {
      fetch(url,options)
        .then(response => response.json())
        .then(response => {
          if (!response.success) {
            setLogin(false);
            setRegister(false);
      
            setMessage("Cancelling Login...");
            setTimeout(() => {
              history.push("/");
            }, 2000);
            reject(response);
          } else {
            setVerification(true);
            resolve(response);
          }
        });
    });
  }

  async function verifyEmail(email, setError) {
    const { url, options } = api.VERIFY_EMAIL(email);

    try {
      const { response, json } = await request(url, options, true);

      if (!response.ok) {
        setError(json);          
        setAuthentication(false);
        return false;
      } else {
        setAuthentication(false);
        setData(json);
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  return (
    <UserContext.Provider
      value={{
        updatePassword,
        verifyAccount,
        confirmationCodeLength,
        start2FAvalidation,
        validate2FASignin,
        userLogin,
        linkedinLogin,
        googleLogin,
        verifyEmail,
        registerUser,
        tokenIsValid,
        validateAccount,
        recoverPassword,
        // userLogout,
        // userCreate,
        data,
        error,
        loading,
        login,
        authentication,
        verification,
        register,
        message,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
