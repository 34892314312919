import React from "react";
import { useHistory } from "react-router";
import Error from "../Helpers/Error";
import useForm from "../Hooks/useForm";
import useQuery from "../Hooks/useQuery";
import { UserContext } from "../UserContext";
import FormContainer from "./FormContainer";
import Button from "./Forms/Button";
import Input from "./Forms/Input";
import styles from "./LegacyLogin.module.css";

const LegacyLogin = () => {
  let email = useForm("email");
  let password = useForm();
  let [waiting, setWaiting] = React.useState(null);

  const history = useHistory();

  let query = useQuery();

  const [brand] = React.useState(query.get("brand"));

  const { userLogin, error, login, message } = React.useContext(UserContext);

  function verifyAccount(){
    fetch("https://cityapi.lotadata.com/auth/verifyAccount",{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email:email.value, password:password.value }),
    }).then(() => {
      document.querySelector(".passwordReset").innerText = "Verification link is sent to your email. Validate your account to proceed with sign in";
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (email.validate() && password.validate()) {
      setWaiting(true);
      window.localStorage.setItem("source", "legacy");
      window.localStorage.setItem("email", email.value);
      await userLogin(email.value, password.value, true);
    }
  }

  React.useEffect(() => {
    login && setWaiting(false);
    error && setWaiting(false);
  }, [login, error]);

  React.useEffect(() => {
    if (window.localStorage.getItem("token"))
      history.push({
        pathname: "/continue",
        search: `${brand ? `?brand=${brand}` : ""}`,
      });
  }, []);

  return (
    <FormContainer className="animeLeft">
      <div className={`${styles.controls} ${brand ? styles[brand] : ""}`}>
        <h2>{brand ? brand : ""} SIGN IN</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            label="Email"
            type="text"
            name="email"
            {...email}
            autoFocus
          />
          <Input
            label="Password"
            type="password"
            name="password"
            {...password}
          />
          <div className="Input_wrapper__20sPW"
            style={{
              margin: "-20px 0px 30px"
            }}>
          </div>

          {waiting ? (
            <Button disabled="disabled">Signing In...</Button>
          ) : login ? (
            <Button disabled="disabled">Success!</Button>
          ) : (
            <Button>Sign In</Button>
          )}
          {error && error.includes("unverified-account") && <>
            <Error error={<button type="button" onClick={verifyAccount} className="passwordReset" style={{
                fontSize: "16px",
                margin: "10px 0 0",
                cursor: "pointer",
                color: "inherit",
                background: "transparent",
                width: "100%",
                border: "none"
              }}>Your account verification is pending, click here to verify your account now</button>}
            />
          </>}
          {error && error.includes("wrong-password") && <>
            <Error error={<a href="/recover" className="passwordReset" style={{
                fontSize: "16px",
                margin: "10px 0 0",
                cursor: "pointer",
                color: "inherit"
              }}>{message}, you can reset it here</a>}
            />
          </>}
          {error && error.includes("not-found") && <>
            <Error error={<a href="/signup" className="passwordReset" style={{
                fontSize: "16px",
                margin: "10px 0 0",
                cursor: "pointer",
                color: "inherit"
              }}>Email not registered, you can create a new account here</a>}
            />
          </>}
        </form>
      </div>
    </FormContainer>
  );
};

export default LegacyLogin;
