import React from "react";

// eslint-disable-next-line react/prop-types
const Error = ({ error, className }) => {
  if (!error) return null;
  return (
    <p
      style={{ color: "#f31", margin: "1rem 0", fontSize: "1rem" }}
      className={className}
    >
      {error}
    </p>
  );
};

export default Error;
