import React from "react";
import { UserContext } from "../UserContext";
import FormContainer from "./FormContainer";
import useQuery from "../Hooks/useQuery";
import styles from "./LegacyLogin.module.css";
import { Link } from "react-router-dom";
import useForm from "../Hooks/useForm";
import Button from "./Forms/Button";
import Input from "./Forms/Input";
import Error from "../Helpers/Error";

const resetPassword = () => {
    let password = useForm();
    let confirm_password = useForm();
    let query = useQuery();
    const [updated,setUpdated] = React.useState(false); 
    const [error,setError] = React.useState(undefined); 
    const [message,setMessage] = React.useState(undefined); 
    const [brand] = React.useState(query.get("brand")); 
    const [oobCode] = React.useState(query.get("oobCode"));
    const [waiting, setWaiting] = React.useState(null);

    const { updatePassword } = React.useContext(UserContext);

    // DO NOT FORCE STRONG PASSWORDS
    // IT COULD BE A DEAL BREAKER WITH CITYOPS FIELD WORKERS

    React.useEffect(async () => {
        console.log(oobCode);
    },[]);

    async function handleSubmit(event) {
        event.preventDefault();
    
        setWaiting(true);

        if (password.value && confirm_password.value) {
            updatePassword(oobCode,password.value,confirm_password.value).then(() => {
                setWaiting(false);
                setUpdated(true);
            }).catch(e => {
                setError(true);
                setWaiting(false);
                setUpdated(false);
                setMessage(e.message);
                console.log(e.message);
            });
        }
    }

    return (<FormContainer className="animeLeft">
        <div className={`${styles.controls} ${brand ? styles[brand] : ""}`}>
            <h2>Update your password</h2>

            <form className={styles.form} onSubmit={handleSubmit}>
                <Input
                    label="New Password"
                    type="text"
                    name="password"
                    {...password}
                    autoFocus
                />

                <Input
                    label="Confirm Password"
                    type="text"
                    name="confirm_password"
                    {...confirm_password}
                />

                {waiting ? (
                  <Button disabled="disabled">Updating...</Button>
                ) : updated ? (
                  <Button disabled="disabled">Success!</Button>
                ) : (
                  <Button>Update password</Button>
                )}

                <p style={{
                    fontSize:"16px",
                    margin:"10px 0px 0px"            
                }}>{updated && <Link
                    className={styles.legacy}
                    to="/signin"
                    aria-label="Sign In"
                > Your password is updated. Sign In. </Link>}</p>

                {!updated && error && <>
                    <Error error={<a href="/recover" className="passwordReset" style={{
                        fontSize: "16px",
                        margin: "10px 0 0",
                        cursor: "pointer",
                        color: "inherit"
                    }}>{message}, you can request a new one here</a>}
                    />
                </>}
            </form>
        </div>
    </FormContainer>);    
};
export default resetPassword;