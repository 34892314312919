import React from "react";
import { useHistory } from "react-router";
import Error from "../Helpers/Error";
import useForm from "../Hooks/useForm";
import useQuery from "../Hooks/useQuery";
import { UserContext } from "../UserContext";
import FormContainer from "./FormContainer";
import Button from "./Forms/Button";
import Input from "./Forms/Input";
import styles from "./LegacyLogin.module.css";

const LegacyLogin = () => {
  let email = useForm("email");
  let [waiting, setWaiting] = React.useState(null);

  const history = useHistory();

  let query = useQuery();

  const [brand] = React.useState(query.get("brand"));

  const { recoverPassword, error, login, message } = React.useContext(UserContext);

  async function handleSubmit(event) {
    event.preventDefault();

    if(email.value) {
      setWaiting(true);
      recoverPassword(email.value);
    }
  }

  React.useEffect(() => {
    login && setWaiting(false);
    error && setWaiting(false);
  }, [login, error]);

  React.useEffect(() => {
    if (window.localStorage.getItem("token"))
      history.push({
        pathname: "/continue",
        search: `${brand ? `?brand=${brand}` : ""}`,
      });
  }, []);

  return (
    <FormContainer className="animeLeft">
      <div className={`${styles.controls} ${brand ? styles[brand] : ""}`}>
        <h2>{brand ? brand : ""} Password Recovery</h2>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            label="Email"
            type="text"
            name="email"
            {...email}
            autoFocus
          />

          {waiting ? (
            <Button disabled="disabled">Sending recovery link to your email...</Button>
          ) : login ? (
            <>
              <Button disabled="disabled">Request sent! Please check your email.</Button>
              <p style={{
                fontSize:"16px",
                margin:"10px 0px 0px"            
              }}> After recover, check <a href="/signin">signin page</a> </p>
            </>
          ) : (
            <Button>Recover</Button>
          )}
          {error && <Error error={message} />}
        </form>
      </div>
    </FormContainer>
  );
};

export default LegacyLogin;
