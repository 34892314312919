/* eslint-disable react/prop-types */
import React from "react";
import useQuery from "../Hooks/useQuery";
import styles from "./FormContainer.module.css";

const FormContainer = ({ children, className, footer = true }) => {
  let query = useQuery();

  const [brand] = React.useState(query.get("brand"));

  return (
    <section className={styles.container}>
      <div className={styles.logoContainer}>
        {brand == "enelx" ? (
          <img
            className={styles.enelLogo}
            src="https://s3.us-west-2.amazonaws.com/lotadata.media/customers/ENEL/Enel_X_Logo_White_RGB.png"
          />
        ) : (
          <img src="https://s3.us-west-2.amazonaws.com/lotadata.media/citydata.ai/images/citydata_new_logo_blue_salmon_transparent.png" />
        )}
      </div>
      <div
        className={`${styles.formContainer} ${className} ${
          brand ? styles[brand] : ""
        }`}
      >
        {children}
        {footer && (
          <div className={styles.footer}>
            <div className={styles.description}>
              <p className={styles.agreements}>
                By clicking the above buttons, you agree to our{" "}
                <a
                  href="https://citydata.ai/company/terms_of_service"
                  target="_blank"
                  rel="follow noreferrer"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://citydata.ai/company/privacy_policy"
                  target="_blank"
                  rel="follow noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        )}
      </div>
      {brand && (
        <div className={styles.powered}>
          <span>Powered by&nbsp;</span>
          <img src="https://s3.us-west-2.amazonaws.com/lotadata.media/citydata.ai/images/citydata_new_logo_blue_salmon_transparent.png" />
        </div>
      )}
    </section>
  );
};

export default FormContainer;
